import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const About = () => {
  return (
    <>
      <Layout>
        <SEO
          title="DDSLocator Dentist Directory  |  Powered by DenMat USA, Canada"
          description="The DDSLocator Dentist Directory connects dental patients to licensed dentists in their geographic area. DDSLocator is powered by DenMat, USA, Canada."
        />
        <Container fluid={true}>
          <Row>
            <Col md="12">
              <div className="main-image mt-4">
                <StaticImage
                  src="../images/DDSLocator-header-2500x651.jpg"
                  alt="Dentists"
                  placeholder="none"
                  layout="fullWidth"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mb-3">
            <Col md="12">
              <h1 className="mt-5 mb-3 title">
                DDSLocator.com Dentist Directory Service in USA and Canada
              </h1>
              <p>
                DDSLocator provides a searchable database of licensed local
                dentists across the United States and Canada. Find dentists in
                your area, learn about their specialties and services and read
                reviews from patients. When you find the right dentist it’s easy
                to contact them to ask a question or set up an appointment. The
                DDSLocator Dentist Directory is powered by DenMat, a worldwide
                provider of dental products, lab services and dental education.
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md="12">
              <h2>Made in the USA</h2>
              <p>
                DenMat operates a state-of-the-art facility at its worldwide
                headquarters in Lompoc, California, USA, and specializes in the
                manufacture of a wide range of high-quality dental products,
                small equipment, and custom laboratory-fabricated dental
                restorations. Our restorative dental products are time-tested by
                thousands of our dentist customers and we are proud to be a part
                of your journey in oral health.
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default About
